.App {
  text-align: center;
}

.section-header {
  font-size: 50px;
  color: #e2ae6c;
  border-top: solid 1px #f2f1ef;
  border-bottom: solid 1px #f2f1ef;
  padding: 20px 0;
  max-width: 420px;
  margin: 0 auto;
}

section {
  padding: 100px 0;
}

.section-background {
  background-color: #6e304b;
}

.section-background-inverse {
  background-color: #22161c;
}

.btn {
  text-decoration: none;
  display: inline-block;
  color: #e2ae6c;
  background-color: #22161c;
  padding: 10px 20px;
  margin-top: 20px;
  font-weight: 900;
  width: 120px;
  border-radius: 10px;
}

.overlay-btn {
  text-decoration: none;
  display: inline-block;
  background-color: #22161c;
  padding: 10px 20px;
  font-weight: 900;
  width: 120px;
  border-radius: 10px;
}

.btn:hover, :focus, .overlay-btn:hover {
  background-color: #6e304b;
  cursor: pointer;
}

.overlay {
  display: none;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal {
  background: #474E68;
  width: 75%;
  margin: 0 auto;
  height: 80%;
  padding: 20px;
  position: relative;
  top: 5%;
  display: flex;
  flex-direction: column;
}

/* .modal {
  background: #f2f1ef;
  max-width: 800px;
  margin: 0 auto;
  height: auto;
  padding: 20px;
  position: relative;
  top: 20%;
} */

a {
  cursor: pointer;
  text-decoration: none;
  color: #e2ae6c;
}

a:visited {
  color: #e2ae6c;
}

h2 {
  margin-top: 0;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  margin-bottom: 5px;
}

.img-tile {
  width: 65px;
  max-width: 60%;
  height: auto;
  border: solid 2px black;
  border-radius: 5px;
  margin-top: 10px;
}

.project-tile-container {
  background: #f2f1ef;
  color: black;
  padding: 50px;
  margin: 10px auto;
}

#description {
  background: lightgray;
  border-style: outset;
  padding: 10px;
}

.description-text {
  font-size: small;
  text-align: justify;
  margin: auto;
}

.diagram {
  width: auto;
  max-height: 400px;

  border-style: outset;
  background: lightgrey;
}

.tiles-column {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  height: 100%;

  border-style: outset;
  background-color: lightgrey;
}

.tiles {
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: center;
}

.small-heading {
  margin: 0;
  color: black;
}

.overlay-btn-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 50px;
}

@media screen and ((min-width: 400px) and (max-width: 1199px)){
  .row {
    flex-direction: column;
  }

  #site-diagram {
    height: 300px;
    width: auto;
  }

  #resume-diagram {
    width: auto;
    height: auto;
  }

  #tech {
   display: none;
  }
}

@media screen and ((min-width: 1200px)){
  .diagram {
    width: 50%;
    max-height: auto;
  }

  .description-text {
    font-size: small;
    text-align: left;
    margin: auto;
  }

  #site-diagram {
    height: auto;
    width: auto;
  }

  #resume-diagram {
    width: auto;
    height: auto;
  }
}
